import React, {Component} from "react";
import axios from "axios";
import Filters from "../Landing/Filters";
import SearchBox from "../Landing/SearchBox";
import LoginModal from "../Modals/LoginModal";
import Header from "../Landing/Header";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Sidebar from "../Landing/Sidebar";
import OpenAI from "openai";
import {
    MdClear,
    MdHighlightOff,
    MdKeyboardArrowRight,
    MdOpenInFull,
    MdOpenInNew,
    MdOutlineFilterAlt
} from "react-icons/md";
import 'react-loading-skeleton/dist/skeleton.css'
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import notfound from "../../../assets/notfound.png"
import ResultComponent from "./ResultComponent";
import AlertComponent from "./AlertComponent";
import {copyLink, saveBookmark} from "./CategoriesFunctions";
import MediaQuery from "react-responsive";
import Pagination from "./Pagination";
import {FaRegBookmark, FaRegCopy} from "react-icons/fa";
import {Link} from "react-router-dom";
import Typewriter from 'typewriter-effect';
import ContentEditable from "react-contenteditable";
import Skeleton from "react-loading-skeleton";
import {Modal, ModalFooter} from "react-bootstrap";

class Results2 extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        /*-----Disables filter when user clicks outside on mobile version */
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    state = {
        results: [],
        results1: [],
        pagination: [],
        siteid: "",
        social: false,
        total: "",
        search: "",
        images: [],
        title: "",
        link: "",
        success: "",
        token: localStorage.getItem('token'),
        show: false,
        related: [],
        count: 15,
        offSet: 0,
        currentItems: null,
        pageCount: 0,
        itemsPerPage: 50,
        copy: false,
        saved: false,
        activePage: 15,
        pager: {},
        perPage: 16,
        page: 0,
        pages: 0,
        searcht: localStorage.getItem('searchquery'),
        configID:`${process.env.REACT_APP_BING_CONFIG_ID}`,
        subscrKey:`${process.env.REACT_APP_BING_SUBSCRIPTION_ID}`,
        active: false,
        showfilter: false,
        loading: true,
        oldest: "",
        topv: "",
        logintype: "",
        exists: "",
        offsetvalue: "0",
        relatedbing: [],
        isTourOpen: false,
        notfound: false,
        reddit:[],
        norelated:false,
        showmodal:false,
        result:[],
        chatgptsecret:process.env.REACT_APP_CHATGPT_SECRET,
        chatgptresponse:"",
        generating: true,
        showLoginModal:false
    }
    componentWillMount() {
        if(window.location.search) {
            localStorage.setItem('searchquery', window.location.search.replace('?q=', " ").replaceAll('%20', " "))
        }

    }
    async generateSummary (){
        let searchterm = localStorage.getItem('searchquery')
        const openai = new OpenAI({
            apiKey:process.env.REACT_APP_CHATGPT_SECRET,
            dangerouslyAllowBrowser: true
        })
        const completion = await openai.chat.completions.create({
            messages:[{
                "role":"user", "content":`what is the definition of ${searchterm} in UX`
            }],
            model:'gpt-4',
            temperature: 1,
            max_tokens: 256,
        })
        this.setState({
            chatgptresponse: completion.choices[0].message["content"],
            generating:false
        })

    }
    async componentDidMount() {
        this.generateSummary()
        //Reset the offset value when the user changes from one category to another
        this.setState({
            offset: parseFloat(0)
        })
        //Close the filter box when the user clicks or taps outside
        document.addEventListener("mousedown", this.handleClickOutside);
        //Get search query from the url and add it to the local storage
        let searchterm = localStorage.getItem('searchquery')
        //Get search results from Bing API
       axios.get(`https://api.bing.microsoft.com/v7.0/custom/search?q=${searchterm} ux&customconfig=${this.state.configID}&count=${this.state.count}&offset=${this.state.offsetvalue}&setLang=en-US`, {
            headers: {
                "Ocp-Apim-Subscription-Key":this.state.subscrKey,
                "token": this.state.token
            }
        }).then((res) => {
            if (res.data.webPages.value === undefined) {
                const response1 = axios.get(`https://api.bing.microsoft.com/v7.0/custom/search?q=${searchterm}&customconfig=${this.state.configID}&count=${this.state.count}&offset=${this.state.offsetvalue}&setLang=en-US`, {
                    headers: {
                        "Ocp-Apim-Subscription-Key": this.state.subscrKey,
                        "token": this.state.token
                    }
                }).then((respo) => {
                    if (respo.data.webPages.value === undefined) {
                        this.setState({
                            notfound: true
                        })
                    } else {
                        if (respo.data.relatedSearches === undefined) {
                            this.setState({
                                notrelated: true
                            })
                            this.setState({
                                results: respo.data.webPages.value,
                                total: respo.data.webPages.totalEstimatedMatches,
                                pagination: respo.data.rankingResponse.mainline.items,
                                /*images: res.data.videos.value,*/
                                loading: false,
                            })
                        } else {
                            this.setState({
                                results: respo.data.webPages.value,
                                total: respo.data.webPages.totalEstimatedMatches,
                                pagination: respo.data.rankingResponse.mainline.items,
                                /*images: res.data.videos.value,*/
                                loading: false,
                                relatedbing: respo.data.relatedSearches.value
                            })
                        }
                    }

                })

            } else {
                //Check if related searches exists. If they do not exists, change the notrelated state to true.
                //The notrelated state is used to hide the not related section if they do not exist.
                if (res.data.relatedSearches === undefined) {
                    this.setState({
                        notrelated: true
                    })
                    this.setState({
                        results: res.data.webPages.value,
                        total: res.data.webPages.totalEstimatedMatches,
                        pagination: res.data.rankingResponse.mainline.items,
                        /*images: res.data.videos.value,*/
                        loading: false,
                    })
                } else {
                    this.setState({
                        results: res.data.webPages.value,
                        total: res.data.webPages.totalEstimatedMatches,
                        pagination: res.data.rankingResponse.mainline.items,
                        /*images: res.data.videos.value,*/
                        loading: false,
                        relatedbing: res.data.relatedSearches.value
                    })
                }
            }

        }).catch((error) => {
            this.setState({
                notfound: true
            })
        })

        this.setState({
            pages: Math.floor(this.state.results.length / this.state.perPage)
        });
        //Get Related Searches from the DB
        axios.get(buildAPIUrl(`v1/save_search_queries/suggest?query=${searchterm}`)).then((rest2) => {
            this.setState({
                related: rest2.data
            })
        })
        if (this.state.results === null) {
            this.setState({
                notfound: true
            })
        }
        //Get videos from the videos API
        axios.get(`https://api.bing.microsoft.com/v7.0/custom/videos/search?q=${searchterm}&customconfig=${this.state.configID}&count=15`, {
            headers: {
                "Ocp-Apim-Subscription-Key": this.state.subscrKey
            }
        }).then((res) => {
            if (res.data.value === undefined) {
                this.setState({
                    notfound: true
                })
            } else {
                this.setState({
                    images: res.data.value,
                })
            }

        })
        const handleScroll = () => {
            // You can adjust the scroll distance condition here
            if(!localStorage.getItem('token')){
                if (window.scrollY > 100) {
                    setTimeout(()=>{
                        this.setState({
                            showLoginModal:true
                        });
                    }, 3000)
                    window.removeEventListener('scroll', handleScroll); // Optional: Remove listener after showing the modal
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll); // Clean up the event listener
        };

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.generating !== this.state.generating && this.state.generating) {
            let i = 0;
            let intervalId = setInterval(() => {
                i += 1;
                this.setState({currentIndex: i});
                if (i >= this.state.chatgptresponse.length) {
                    clearInterval(intervalId);
                    this.setState({generating: false});
                }
            }, 100);
        }
    }
//Hide filter when user clicks outside the filter box on mobile
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    };

    //Allow users to search for related searches on the platform
    handleLinkSearch = async (link) => {
        localStorage.setItem('searchquery', link)
        window.location.assign('/all-results')
    }
//Accept user input
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
//Filters
    handleFilterByOldest = async (e) => {
        let searchterm = localStorage.getItem('searchquery')
        const isChecked = e.target.checked
        if (isChecked) {
            this.setState({
                oldest: e.target.name,
            })
            const oldest = [...this.state.results].sort((a, b) => new Date(b.dateLastCrawled) - new Date(a.dateLastCrawled))
            await this.setState({
                results: oldest
            })
        } else {
            this.setState({
                oldest: "",
            })
           axios.get(`https://api.bing.microsoft.com/v7.0/search?q=${searchterm}&customconfig=${this.state.configID}&count=${this.state.count}`, {
                headers: {
                    "Ocp-Apim-Subscription-Key": this.state.subscrKey,
                    "token": this.state.token
                }
            }).then((res) => {
                this.setState({
                    results: res.data.webPages.value,
                    total: res.data.webPages.totalEstimatedMatches,
                    pagination: res.data.rankingResponse.mainline.items
                })
            })
        }


    }
//Clear filters
    onClearFilters = async (event) => {
        await this.setState({
            tags: ""
        })
        window.location.reload()
    }

    //Open and close modal
    handleOpen = () => {
        this.setState({
            active: true
        })
    }
    handleClose = () => {
        this.setState({
            active: false
        })
    }
    handleModalOpen = (result, logtype)=>{

        this.setState({
            show: true,
            siteid:result,
            logintype:logtype
        })
    }
    handleModalClose = (result, logtype)=>{

        this.setState({
            show: false,
            siteid:result,
            logintype:logtype
        })
    }
    handleFilterDiv = () => {
        if (this.state.showfilter) {
            this.setState({
                showfilter: false
            })
        } else {
            this.setState({
                showfilter: true
            })
        }

    }
    handleClickOutside = (event) => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({
                showfilter: false
            })
        }

    }
    /*-----Copy results link------*/
    handleCopyLink = (result)=>{
        copyLink(result, (copystatus)=>{
            this.setState({
                copy:copystatus
            })
            setTimeout(()=>{
                this.setState({
                    copy:false
                })
            }, 5000)
        })

    }
    /*-----Bookmark Result------*/
    handleSaveSearch = (result, bookmarktype)=>{
        saveBookmark(result, bookmarktype, (savestatus)=>{
            this.setState({
                saved:savestatus
            })
            setTimeout(()=>{
                this.setState({
                    saved:false
                })
            }, 10000)

        }, (error)=>{
            this.setState({
                exists:error
            })
            setTimeout(()=>{
                this.setState({
                    exists:""
                })
            }, 20000)
        })
    }

    componentDidUnmount() {
        localStorage.setItem('blinking', '1')
    }
    handleFilterShow = () => {
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }
    }

    render() {
        const {page, perPage, pages} = this.state;
        let items = this.state.results.slice(page * perPage, (page + 1) * perPage);
        return (
            <>

              {/*  <Preview openpreview={this.handlePreview} />*/}
             {/*   <LoginModal device={"desktop"}  show={this.state.showLoginModal}/>*/}
                <MediaQuery minWidth={1200} >
                    <Header/>
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem", marginTop:"7rem"}}>
                        <div className="col-md-1"></div>
                        <div className="col-md-10 col-12">
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-12 col-md-11"><SearchBox web={true}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                {
                                    this.state.notfound ? " " :
                                        <Filters web={true}/>
                                }

                            </div>
                            <div className="row">
                                <div className=" col-12 p-0 col-md-7" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className={"col-md-3"}>
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"General"}/>
                                        </div>

                                        <div className="container p-0 mt-3 mb-3 results">
                                            {
                                                this.state.notfound ? <h6>0 results found</h6> :
                                                    <h6>About {
                                                        this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                    } results </h6>
                                            }
                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img src={notfound}/>
                                                            </div>

                                                            <div className="search-message text-center">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>We couldn’t find results for this keyword, try another term</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.generating ? "Summarizing this concept..." :
                                                           <>
                                                               {
                                                                   this.state.chatgptresponse ?  <div className={"container-fluid pt-4 pb-4 bg-light border mt-2 mb-4"}>
                                                                       <h6 style={{fontWeight:"400", lineHeight:"150%",}}>
                                                                           <ContentEditable
                                                                               innerRef={this.state.chatgptresponse}
                                                                               html={this.state.chatgptresponse.replace("\n\n", "").replace("design", "").replace("Design?", "")}
                                                                               disabled={true}       // use true to disable editing
                                                                               tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                           />

                                                                       </h6>
                                                                   </div> : ""
                                                               }
                                                           </>
                                                    }

                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>

                                                                {
                                                                    items.slice(0,1).map((result) => (
                                                                        <>

                                                                            <ResultComponent handleTour={(closetou)=>(this.setState({isTourOpen:closetou}))} tooltip={this.state.isTourOpen} handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                                <div className={"container bg-light p-3"}>
                                                                    <h6>Related videos</h6>
                                                                    <div className={"row"}>
                                                                        {
                                                                            this.state.images.slice(0,3).map((result)=>(
                                                                                <div className={"col-md-4"}>
                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none", width:"100%"  }}><img
                                                                                        src={result.thumbnailUrl}
                                                                                        className="video-img" style={{objectFit:"cover", height:"auto", objectPosition:"center"}}  /></a>
                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none"}}><h6
                                                                                        className="result-name mt-2 mb-2">{result.name}</h6>
                                                                                    </a>
                                                                                    <p className="result-snippet">
                                                                                        {result.description ? result.description.substr(0,50) + "..." : ""}
                                                                                    </p>
                                                                                    {
                                                                                        this.state.token ? <span className="save"
                                                                                                                 onClick={() => this.handleSaveSearch(result, "Videos")}> Save <FaRegBookmark
                                                                                                className="save-icon"/></span> :
                                                                                            <span className="save "
                                                                                                  onClick={() => this.handleModalOpen(result, "Videos")}> Save <FaRegBookmark/> </span>
                                                                                    }
                                                                                    <span className="save" onClick={() => {
                                                                                        this.handleCopyLink(result)
                                                                                    }}> Copy link <FaRegCopy
                                                                                        className="save-icon"/></span>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className={"mt-5"}>
                                                                    {
                                                                        items.slice(1,15).map((result) => (
                                                                            <>
                                                                                <ResultComponent  handleMod={(result, logtype)=>this.setState({show: true,
                                                                                    siteid:result,
                                                                                    logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                    saved:savedstatus,
                                                                                    exists:existstatus,
                                                                                })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>

                                                            </div>
                                                    }
                                                </>
                                        }
                                        <div className={this.state.notfound ? "d-none" : ""}>
                                            <Pagination paginateoff={this.state.offset}  increment={15} type={"configID"} count={this.state.count}
                                                         handleNext={(response, totalcount, paginationcount,loadingstate, offset)=>
                                                             this.setState({
                                                                 results:response,
                                                                 total:totalcount,
                                                                 pagination:paginationcount,
                                                                 loading:loadingstate,
                                                                 offset:offset
                                                             })}
                                            />
                                        </div>

                                    </div>

                                </div>
                                <div className={"col-md-1"}></div>
                                <div className={"col-md-4 "} style={{marginTop:"3.125rem"}}>
                                        {
                                            this.state.norelated ? " " : <div className={"container"} style={{padding:"1.25rem", border:"1px solid #EDEDED", borderRadius:"7px"}}>
                                                <div className={"row"}>
                                                    <div className={"col-md-12"}>
                                                        {
                                                            this.state.relatedbing.length > 0 ?
                                                                <h6>
                                                                    Related Searches
                                                                </h6> : ""
                                                        }
                                                        {
                                                            this.state.relatedbing.length > 0 ? <>
                                                                {
                                                                    this.state.relatedbing.slice(0,6).map((related)=>(
                                                                        <>
                                                                            <div onClick={()=>this.handleLinkSearch(related.text)} className={" relatedsearches"}>
                                                                                <h6>{related.text}</h6>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                }
                                                            </> : <></>
                                                        }
                                                    </div>
                                                </div>


                                            </div>
                                        }


                                </div>
                            </div>
                        </div>

                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div ref={this.ref} className={this.state.showfilter? "row  p-0 d-flex justify-content-center position-absolute" : " d-none mobfilter1"} style={{zIndex:"1000", height:"80vh", width:"100%"}} >
                        <div className=" bg-white px-4" >
                            <div className={"row mt-3"} style={{boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.13)", paddingLeft:"0rem"}}>
                                <div className={"col-4"}>
                                    <span style={{color:"#00944D", fontSize:"14px",  right:"0", cursor:"pointer"}} onClick={this.clearFilters}>Reset</span>
                                </div>
                                <div className={"col-4"}>
                                    <h5 style={{fontSize:"20px", color:"#070707"}}>Filters </h5>
                                </div>
                                <div className={"col-4 d-flex justify-content-end"} style={{cursor:"pointer"}}>
                                    <MdHighlightOff onClick={this.handleFilterDiv} style={{fontSize:"20px"}} />
                                </div>
                            </div>
                            <h6 className={"filter-title1 text-center"} style={{marginTop:"1rem"}}>Sort by relevance</h6>
                            <hr/>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="Free"
                                       id="flexRadioDefault1" onChange={this.handleFilterByOldest}/>
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Sort by latest
                                </label>
                            </div>

                        </div>
                        <button onClick={this.handleFilterShow} className={"btn btn-save-filter"}>
                            Save Filter
                        </button>
                    </div>
                    <Header/>
                    <div className="mobile row position-relative">

                        <div className={this.state.active ? "col-md-2 col-2  " : "col-md-1 col-2 hidden-mob"}>
                            <Sidebar closeSideBar={this.handleClose} openSideBar={this.handleOpen}
                                     status={this.state.active}/>
                        </div>

                        <div
                            className={this.state.active ? "col-md-8 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div className=" col-12 col-md-11"><SearchBox web={true} device={"mobile"}/></div>
                                </div>

                            </div>
                            <div className="row" >
                                {
                                    this.state.notfound ? " " :
                                        <>
                                            <div className={"row m-0 d-flex align-items-center"} style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                                                <div className={"col-12 p-0"}>
                                                    <Filters device={"mobile"} web={true}/>
                                                </div>
                                            </div>
                                        </>

                                }


                            </div>

                            <div className="row ">
                                <div className=" col-12 p-0 col-md-10" style={{overflow: "hidden"}}>
                                    <div className="container p-0 position-relative">
                                        <div className="container p-0 results ">
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"General"}/>
                                            <div className="row" style={{marginTop:"12.5px", paddingLeft: "1.5rem", paddingRight: "1.5rem"}}>
                                                <div className="col-8 d-flex align-items-center" >
                                                    {
                                                        this.state.notfound ? <h6>0 results found</h6> :
                                                            <h6>About {
                                                                this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                            } results </h6>
                                                    }

                                                </div>
                                                <div className={"col-4 d-flex justify-content-end"}>
                                                    <a className="mobfilter1 d-flex justify-content-end" onClick={this.handleFilterDiv}><h6 className="h6-filter" style={{color:"#00944D"}} >Filter </h6></a>
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img src={notfound}/>
                                                            </div>

                                                            <div className="search-message text-center">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>We couldn’t find results for this keyword, try another term</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div style={{background:"#F8F8F8"}} className={"p-0"}>
                                                                {
                                                                    items.slice(0,1).map((result) => (
                                                                        <>
                                                                            <ResultComponent handleTour={(closetou)=>(this.setState({isTourOpen:closetou}))}  tooltip={this.state.isTourOpen} handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}} position={"first"}/>
                                                                        </>
                                                                    ))
                                                                }
                                                                <div className={"container bg-white p-3"}>
                                                                    <h6 className={"px-2"}>Related videos</h6>
                                                                    <div className={"row px-2"}>
                                                                        {
                                                                            this.state.images.slice(0,2).map((result)=>(
                                                                                <div className={"col-6"}>
                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none", width:"100%"  }}><img
                                                                                        src={result.thumbnailUrl}
                                                                                        className="video-img" style={{objectFit:"cover", height:"auto", objectPosition:"center"}}  /></a>
                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none"}}><h6
                                                                                        className="result-name mt-2 mb-2">{result.name.substr(0,20)  + "..."}</h6>
                                                                                    </a>
                                                                                   {/* <p className="result-snippet">
                                                                                        {result.description.substr(0,50) + "..."}
                                                                                    </p>*/}
                                                                                    {
                                                                                        this.state.token ? <span className="save"
                                                                                                                 onClick={() => this.handleSaveSearch(result, "Videos")}> Save <FaRegBookmark
                                                                                                className="save-icon"/></span> :
                                                                                            <span className="save "
                                                                                                  onClick={() => this.handleModalOpen(result, "Videos")}> Save <FaRegBookmark/> </span>
                                                                                    }
                                                                                    <span className="save" onClick={() => {
                                                                                        this.handleCopyLink(result)
                                                                                    }}> Copy link <FaRegCopy
                                                                                        className="save-icon"/></span>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                    </div>
                                                                    <div className={"container-fluid mt-3 d-flex justify-content-center"}>
                                                                        <Link to={"/videos"} className={"btn btn-dark"} style={{borderRadius:"0"}}>View more videos  <MdKeyboardArrowRight /></Link>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    items.slice(1,7).map((result) => (
                                                                        <>
                                                                            <ResultComponent handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                                {
                                                                    this.state.norelated ? " " : <div className={"container p-0 "}>
                                                                        <div className={"row px-2"}>
                                                                            <div className={"col-md-12"}>
                                                                                {
                                                                                    this.state.relatedbing.length > 0 ?
                                                                                        <h6 className={"px-3"}>
                                                                                            Related Searches
                                                                                        </h6> : ""
                                                                                }
                                                                                {
                                                                                    this.state.relatedbing.length > 0 ? <>
                                                                                        {
                                                                                            this.state.relatedbing.slice(0,6).map((related)=>(
                                                                                                <>
                                                                                                    <div onClick={()=>this.handleLinkSearch(related.text)} className={" relatedsearches"}>
                                                                                                        <h6>{related.text}</h6>
                                                                                                    </div>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </> : <></>
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                }
                                                                {
                                                                    items.slice(7,16).map((result) => (
                                                                        <>
                                                                            <ResultComponent handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }

                                                            </div>
                                                    }
                                                </>
                                        }
                                        <div>
                                        </div>
                                        <div className={this.state.notfound ? "d-none" : ""}>
                                            <Pagination paginateoff={this.state.offset} increment={15} type={"configID"} count={this.state.count}
                                                        handleNext={(response, totalcount, paginationcount,loadingstate, offset)=>
                                                            this.setState({
                                                                results:response,
                                                                total:totalcount,
                                                                pagination:paginationcount,
                                                                loading:loadingstate,
                                                                offset:offset
                                                            })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <Header/>
                    <div className="row tablet" style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-12 col-md-12"><SearchBox web={true}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                {
                                    this.state.notfound ? " " :
                                        <Filters device={"tablet"} web={true}/>
                                }


                            </div>
                            <div className="row">
                                <div className=" col-12 p-0 col-md-12" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"General"}/>
                                            <div className="row">
                                                <div className="col-12">
                                                    {
                                                        this.state.notfound ? <h6>0 results found</h6> :
                                                            <h6>About {
                                                                this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                            } results </h6>
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img src={notfound}/>
                                                            </div>

                                                            <div className="search-message text-center">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>We couldn’t find results for this keyword, try another term</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>
                                                                {
                                                                    items.slice(0,1).map((result) => (
                                                                        <>
                                                                            <ResultComponent handleTour={(closetou)=>(this.setState({isTourOpen:closetou}))}  tooltip={this.state.isTourOpen} handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                                <div className={"container-fluid bg-light border pt-3 pb-3"} style={{borderRadius:"4px"}}>
                                                                    <h6>Related videos</h6>
                                                                    <div className={"row "}>
                                                                        {
                                                                            this.state.images.slice(0,3).map((result)=>(
                                                                                <div className={"col-md-4 col-4"}>
                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none", width:"100%"  }}><img
                                                                                        src={result.thumbnailUrl}
                                                                                        className="video-img" style={{objectFit:"cover", height:"150px", objectPosition:"center"}}  /></a>
                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none"}}><h6
                                                                                        className="result-name mt-2 mb-2">{result.name.substr(0,20)}...</h6>
                                                                                    </a>
                                                                                    <p className="result-snippet">
                                                                                        {result.description ? result.description.substr(0,50) + "..." : ""}
                                                                                    </p>
                                                                                    {
                                                                                        this.state.token ? <span className="save"
                                                                                                                 onClick={() => this.handleSaveSearch(result, "Videos")}> Save <FaRegBookmark
                                                                                                className="save-icon"/></span> :
                                                                                            <span className="save "
                                                                                                  onClick={() => this.handleModalOpen(result, "Videos")}> Save <FaRegBookmark/> </span>
                                                                                    }
                                                                                    <span className="save" onClick={() => {
                                                                                        this.handleCopyLink(result)
                                                                                    }}> Copy link <FaRegCopy
                                                                                        className="save-icon"/></span>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                    </div>
                                                                </div>

                                                                <div className={"mt-5"}>
                                                                    {
                                                                        items.slice(1,7).map((result) => (
                                                                            <>
                                                                                <ResultComponent handleMod={(result, logtype)=>this.setState({show: true,
                                                                                    siteid:result,
                                                                                    logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                    saved:savedstatus,
                                                                                    exists:existstatus,
                                                                                })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>
                                                                {
                                                                    this.state.norelated ? " " : <div className={"container p-0 "}>
                                                                        <div className={"row px-2"}>
                                                                            <div className={"col-md-12"}>
                                                                                {
                                                                                    this.state.relatedbing.length > 0 ?
                                                                                        <h6 className={"px-3"}>
                                                                                            Related Searches
                                                                                        </h6> : ""
                                                                                }
                                                                                {
                                                                                    this.state.relatedbing.length > 0 ? <>
                                                                                        {
                                                                                            this.state.relatedbing.slice(0,6).map((related)=>(
                                                                                                <>
                                                                                                    <div onClick={()=>this.handleLinkSearch(related.text)} className={" relatedsearches"}>
                                                                                                        <h6>{related.text}</h6>
                                                                                                    </div>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </> : <></>
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                }

                                                                <div className={"mt-5"}>
                                                                    {
                                                                        items.slice(7,16).map((result) => (
                                                                            <>
                                                                                <ResultComponent handleMod={(result, logtype)=>this.setState({show: true,
                                                                                    siteid:result,
                                                                                    logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                    saved:savedstatus,
                                                                                    exists:existstatus,
                                                                                })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                    }
                                                </>
                                        }

                                        <div>
                                        </div>
                                        <div className="container p-0 mt-4">
                                            <div className={this.state.notfound ? "d-none" : ""}>
                                                <Pagination  paginateoff={this.state.offset} increment={15} type={"configID"} count={this.state.count}
                                                            handleNext={(response, totalcount, paginationcount,loadingstate,offset)=>
                                                                this.setState({
                                                                    results:response,
                                                                    total:totalcount,
                                                                    pagination:paginationcount,
                                                                    loading:loadingstate,
                                                                    offset:offset
                                                                })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <Footer/>
            </>
        )
    }
}

export default Results2