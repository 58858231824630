import React, {Component} from "react";
import originalcard from "../../../assets/Rectangle 498.png"
import Header from "../Landing/Header";
import Breadcrumbs from "./Breadcrumbs";
import MediaQuery from "react-responsive";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
import SkeletonImage from "../Landing/Skeletons/SkeletonImage";
import lock from "../../../assets/Lock.png"
import Calendar from "../../../assets/Calendar.png";
import {
    MdArrowRight,
    MdGrid3X3,
    MdGridView, MdHighlightOff,
    MdKeyboardArrowDown,
    MdOpenInNew,
    MdReorder,
    MdTrendingFlat
} from "react-icons/md";
import {Accordion} from "react-bootstrap";
import {FaAngleDown, FaAngleUp} from "react-icons/fa";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import ResourcesNav from "../RBResourceTables/ResourcesNav";
import searchicon from "../../../assets/Frame 39.png";
import PopularBanner from "../RBResourceTables/PopularBanner";
import cards from "../../../assets/Group 23.png";
import NotFound from "../RBResourceTables/NotFound";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import SeoMetaData from "../RBResourceTables/SeoMetaData";
class OriginalsList extends Component{
    state={
        items:[],
        loading:true,
        listview:false,
        showfilter:false,
        plantype: 0,
        type:"",
        types:[],
        popular:[],
        filter:"",
    }
    //Show filter categories
    handleFilterShow = () => {
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }
    }
    //Search function
    handleSearch = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    componentDidMount() {
        EventsHandler("Templates Section Opened",  document.URL)
        //Get the original's list
        const response = axios.get(buildAPIUrl(`v1/rb_originals/`)).then((res)=>{
            console.log(res.data)
            this.setState({
                items:res.data,
                loading:false
            })
        }).catch((error)=>{

        })
        //Get the filter categories from the db
        const response1 = axios.get(buildAPIUrl(`v1/rb_originals_type/`)).then((res)=>{
            console.log(res.data, "filters")
            this.setState({
                types:res.data
            })
        }).catch((error)=>{

        })
        //Check user subscription plan. If the response is empty, then the user is on freemium. If there is a response, then the user is on an Individual plan
        const plansresponse = axios.get(buildAPIUrl('v1/subscriptions/'), {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            if(res.data.length > 0){
                this.setState({
                    plantype:1
                })
            }
        }).catch((error)=>{

        })
        //Get featured / Popular images
        const featured = axios.get(buildAPIUrl(`v1/featured_post/?category_id=6`)).then((res)=>{
            console.log(res, "popular")
            this.setState({
                popular:res.data
            })
        }).catch((error)=>{

        })
        //Increase views by 1
        axios.get(buildAPIUrl('v1/collections/10/')).then(()=>{

        }).catch((error)=>{

        })
    }
    //Change from gridview to list view or vice versa
    handleChangeView = () =>{
        if(this.state.listview){
            this.setState({
                listview:false
            })
        } else {
            this.setState({
                listview:true
            })
        }
    }
    onChangeType = async (event) => {
        if (event.target.value === "all"){
            const response = axios.get(buildAPIUrl(`v1/rb_originals/`)).then((res)=>{
                console.log(res.data)
                this.setState({
                    items:res.data,
                    loading:false
                })
            }).catch((error)=>{

            })
        } else {
            await this.setState({
                type:event.target.value,
            })
            const response = axios.get(buildAPIUrl(`v1/rb_originals/?type=${this.state.type}`)).then((res)=>{
                console.log(res.data)
                this.setState({
                    items:res.data,
                    loading:false
                })
            }).catch((error)=>{

            })
        }

    }
    saveEvent = (id)=> {
        EventsHandler("Template Link Clicked", document.URL)
        //Increase views by 1
        axios.get(buildAPIUrl(`v1/v1/rb_originals//${id}/`)).then(()=>{

        }).catch((error)=>{

        })
    }
    render() {
        const filtereditems = this.state.items.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.title.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })
        return (
            <>
                <SeoMetaData title={"Templates"} />
                <Header pageType={"Free"} />
                <div className="originals-list">
                    <MediaQuery minWidth={1200} >
                        <div className={"row"} style={{paddingLeft: "1rem", paddingRight:"1rem", marginTop:"7rem"}}>
                            <div className={"col-md-2 side-filters"}></div>
                            <div className={"col-md-8  col-12 px-4"}>
                                <div className="">
                                    <div className="search-section col-12 col-md-12 mt-4 ">
                                        <div className="row d-flex align-items-center ">
                                            <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                    <div className="form-input input-group border"  style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                        <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                               style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for podcasts"/>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={"saved-sources"}>
                                        <div className="row">
                                            <ResourcesNav />
                                            <div className="col-md-12 col-6 border p-0 rounded-3" style={{marginTop:"1rem"}}>
                                                <div className={"container-fluid p-0 mt-3 border-bottom"}>
                                                    <div className={"container py-3"}>
                                                        <h1 style={{fontSize:"20px", marginLeft:"0"}}>An Extensive Library of UX Research Templates and Examples</h1>
                                                    </div>
                                                </div>
                                                <div className={"container pb-5"} style={{backgroundColor:"#F2F2F2"}}>
                                                    <PopularBanner name={"RB library"} />
                                                </div>
                                                <div>
                                                    {
                                                        this.state.loading ? <>
                                                            <div className="row mt-5">

                                                                {
                                                                    [1, 2, 3, 4].map((n) => (
                                                                        <div className="col-md-3">
                                                                            <SkeletonImage key={n}/>
                                                                        </div>
                                                                    ) )
                                                                }

                                                            </div>

                                                        </> :  <div className="row">
                                                            {
                                                                filtereditems.length > 0 ?
                                                                    <>
                                                                        {
                                                                            filtereditems.map((item)=>(
                                                                                <>
                                                                                    <div className="col-md-3">
                                                                                        <Link onClick={()=>this.saveEvent(item.id)} to={`/templates/${item.id}`} style={{textDecoration:"none"}}>
                                                                                            <div className="h-100 p-0 originals-card">
                                                                                                <img src={item.image} style={{width:"100%", borderRadius:"11px"}}/>
                                                                                                <div>
                                                                                                    <h6 className={"p-0 orig-title"}>
                                                                                                        {item.title}
                                                                                                    </h6>
                                                                                                </div>

                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>

                                                                                </>

                                                                            ))
                                                                        }

                                                                    </>

                                                                    : <div className={"container d-flex justify-content-center"}>
                                                                        <div className="search-message">
                                                                            <img src={cards}/><br/>
                                                                            <h6>No results found for this keyword</h6>
                                                                            <p>This word is not available in the templates list, try our search
                                                                                engine instead</p>
                                                                            <NotFound term={this.state.filter} />
                                                                        </div>
                                                                    </div>
                                                            }

                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </MediaQuery>
                    <MediaQuery maxWidth={460}>
                        <div className="container mobile">
                            <div ref={this.ref} className={this.state.showfilter? "row  p-0 d-flex justify-content-center position-absolute" : " d-none mobfilter1"} style={{zIndex:"1000", height:"80vh", width:"100%"}} >
                                <div className=" bg-white border-top px-4" >

                                    <div className={"row mt-3"} style={{paddingLeft:".5rem"}}>
                                        <div className={"col-4"}>
                                            <span style={{color:"#00944D", fontSize:"14px",  right:"0", cursor:"pointer"}} onClick={this.clearFilters}>Reset</span>
                                        </div>
                                        <div className={"col-4"}>
                                            <h5 style={{fontSize:"20px", color:"#070707"}}>Filters </h5>
                                        </div>
                                        <div className={"col-4 d-flex justify-content-end"} style={{cursor:"pointer"}}>
                                            <MdHighlightOff onClick={this.handleFilterShow} style={{fontSize:"20px"}} />
                                        </div>
                                    </div>
                                    <h6 className={"filter-title1 text-center"} style={{marginTop:"1rem"}}>Sort by</h6>
                                    <hr/>
                                    <div className="" onChange={this.onChangeValue} >
                                        <h6 className={"filter-title1"} style={{marginTop:"1rem"}}>Category</h6>
                                        <div className="form-check" >
                                            <input className="form-check-input" type="checkbox" name="location"
                                                   id="flexRadioDefault1" value="virtual" checked={this.state.locationtags === "virtual"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Articles
                                            </label>
                                        </div>
                                        <div className="form-check" >
                                            <input className="form-check-input" type="checkbox" name="location"
                                                   id="flexRadioDefault1" value="virtual" checked={this.state.locationtags === "virtual"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Videos
                                            </label>
                                        </div>
                                        <div className="form-check" >
                                            <input className="form-check-input" type="checkbox" name="location"
                                                   id="flexRadioDefault1" value="virtual" checked={this.state.locationtags === "virtual"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Virtual
                                            </label>
                                        </div>
                                        <div className="form-check" >
                                            <input className="form-check-input" type="checkbox" name="location"
                                                   id="flexRadioDefault1" value="virtual" checked={this.state.locationtags === "virtual"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Podcast
                                            </label>
                                        </div>
                                        <div className="form-check" >
                                            <input className="form-check-input" type="checkbox" name="location"
                                                   id="flexRadioDefault1" value="virtual" checked={this.state.locationtags === "virtual"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Images
                                            </label>
                                        </div>
                                        <div className="form-check" >
                                            <input className="form-check-input" type="checkbox" name="location"
                                                   id="flexRadioDefault1" value="virtual" checked={this.state.locationtags === "virtual"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Jobs
                                            </label>
                                        </div>

                                    </div>
                                    <hr />
                                    <h6 className={"filter-title1"} style={{marginTop:"1rem"}}>Time</h6>
                                    <div className="" onChange={this.onChangeValueLocation}>
                                        <div className="form-check" >
                                            <input className="form-check-input" type="radio" name="location"
                                                   id="flexRadioDefault1" value="virtual" checked={this.state.locationtags === "virtual"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Old to New
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="location"
                                                   id="flexRadioDefault1" value="onsite" checked={this.state.locationtags === "onsite"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                New to Old
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <button onClick={this.handleFilterShow} className={"btn btn-save-filter"}>
                                    Save Filter
                                </button>
                            </div>
                            <div className={"col-md-8"}>
                                <div className="container-fluid p-0 mobile">
                                    <div className="search-section col-12 col-md-12 mb-2 mt-4 ">
                                        <div className="row d-flex align-items-center ">
                                            <div className=" col-12 col-md-12 d-flex justify-content-center p-0">
                                                <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                    <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                        <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                        <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                               style={{width: "100%", border:"none"}} placeholder="Search for templates"/>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="saved-sources">
                                        <div className="row d-flex align-items-center">
                                            <ResourcesNav />
                                        </div>
                                        <div className="col-md-8 col-8">
                                            <h6 style={{fontSize:"14px",  fontWeight:"600"}}>UX research templates</h6>
                                        </div>
                                        <PopularBanner name={"RB library"} />
                                        <div className={"row d-flex align-items-center"}>

                                            <div className={"col-4 "}>

                                            </div>
                                        </div>
                                        <h1 style={{marginTop:"1.5rem", marginBottom:"0"}}>An Extensive Library of UX Research Templates and Examples</h1>

                                            <div>
                                                {
                                                    this.state.loading ? <>
                                                            <div className="row">

                                                                {
                                                                    [1, 2, 3, 4].map((n) => (
                                                                        <div className="col-md-4">
                                                                            <SkeletonSearchResult key={n}/>
                                                                        </div>
                                                                    ) )
                                                                }

                                                            </div>

                                                        </> :

                                                        <div className="p-0 mt-3 pb-5">

                                                            {
                                                               filtereditems.length > 0 ?
                                                                    <>
                                                                        <div className={"row"}>
                                                                            {
                                                                               filtereditems.map((item)=>(
                                                                                    <>
                                                                                        <div className={"col-md-6"}>
                                                                                            <div className={"row px-3"} >
                                                                                                <div className="col-md-12 originals-list " style={{ borderTop:"0.5px solid rgba(235, 235, 235, 0.9)", paddingTop:"15px", marginTop:"15px"}}>
                                                                                                    <Link onClick={()=>this.saveEvent(item.id)} to={`/templates/${item.id}`} style={{textDecoration:"none"}}>
                                                                                                        <div className="card-body  p-0">
                                                                                                            <div className={"row originals-list-view-items d-flex align-items-center mt-3"}>
                                                                                                                <div className={"col-5"}>
                                                                                                                    <img src={item.image} style={{ width:"100%", borderRadius:"5px"}}/>
                                                                                                                </div>
                                                                                                                <div className={"col-7"}>
                                                                                                                    <h6 style={{color:"#070707", fontSize:"20px", textDecoration:"underline"}}>
                                                                                                                        {item.title}
                                                                                                                    </h6>
                                                                                                                    <h6 style={{color:"#070707", fontSize:"12px", fontWeight:"400", lineHeight:"15px"}}>

                                                                                                                    </h6>
                                                                                                                    <h6 style={{color:"#00944D", fontSize:"10px", fontWeight:"400", lineHeight:"15px"}}>
                                                                                                                        Read now
                                                                                                                    </h6>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                </div>
                                                                                                <div className={"col-md-4"}></div>
                                                                                                <div className={"col-md-2 d-flex align-items-center"}>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </>

                                                                                ))
                                                                            }
                                                                        </div>


                                                                    </>

                                                                    : <div className={"container d-flex justify-content-center"}>
                                                                        <div className="search-message">
                                                                            <img src={cards}/><br/>
                                                                            <h6>No results found for this keyword</h6>
                                                                            <p>This word is not available in the templates list, try our search
                                                                                engine instead</p>
                                                                            <NotFound term={this.state.filter} />
                                                                        </div>
                                                                    </div>
                                                            }

                                                        </div>
                                                }

                                            </div>

                                    </div>
                                </div>
                            </div>



                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={1200}  minWidth={461} >
                        <Breadcrumbs type={"list"} />
                        <div className="container tablet">
                            <h1 className={"library-title"}>
                            An Extensive Library of UX Research Templates and Examples
                            </h1>
                            <PopularBanner name={"RB library"} />
                            <div className={"originals-navigation"}>
                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <div className="align-items-center dropdow ">
                                            <a className="" target="_blank" style={{color:"black", fontSize:"14px", width: "100%", textDecoration:"none"}}>
                                                <div className="dropdown-toggle originals-toggle" id="navbarDropdown"
                                                     role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                                    <h6>
                                                        <span>
                                                              Category <MdKeyboardArrowDown />
                                                        </span>

                                                    </h6>
                                                </div>
                                                <div className="dropdown-menu originals-category-filter" aria-labelledby="navbarDropdown">
                                                    <div className={"container-fluid p-0"}>
                                                        <div className="" >
                                                            {
                                                                this.state.types.map((type)=>(
                                                                    <div className="form-check" >
                                                                        <input className="form-check-input" type="checkbox" name="location"
                                                                               id="flexRadioDefault1" value={type.id} checked={this.state.locationtags === type.id} />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                            {type.name}
                                                                        </label>
                                                                    </div>
                                                                ))
                                                            }

                                                        </div>
                                                    </div>

                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 d-flex justify-content-end"}>
                                        <div onClick={this.handleChangeView} style={{cursor:"pointer"}}>
                                            {
                                                this.state.listview ?  <span >
                                                <MdGridView style={{border:"2px solid #200E32", fontSize:"25px", color:"#A69FAD", borderRadius:"3px"}}/>
                                            </span> :  <span >
                                                <MdGridView style={{border:"2px solid #200E32", fontSize:"25px", color:"#A69FAD", borderRadius:"3px", background:"black"}}/>
                                            </span>
                                            }
                                            {
                                                this.state.listview ?
                                                    <span>
                                                 <MdReorder style={{
                                                     marginLeft: "1rem",
                                                     color: "white",
                                                     background: "black",
                                                     border: "1.5px solid #200E32",
                                                     fontSize: "25px",
                                                     borderRadius: "3px"
                                                 }}/>
                                           </span> : <span>
                                                 <MdReorder style={{
                                                     marginLeft: "1rem",
                                                     color: "#A69FAD",
                                                     background: "white",
                                                     border: "1.5px solid #200E32",
                                                     fontSize: "25px",
                                                     borderRadius: "3px"
                                                 }}/>
                                           </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.listview ?
                                //List view
                                <div>
                                    {
                                        this.state.loading ? <>
                                                <div className="row">

                                                    {
                                                        [1, 2, 3, 4].map((n) => (
                                                            <div className="col-md-4">
                                                                <SkeletonSearchResult key={n}/>
                                                            </div>
                                                        ) )
                                                    }

                                                </div>

                                            </> :

                                            <div className="p-0 mt-5">

                                                {
                                                    filtereditems.length > 0 ?
                                                        <>
                                                            <div className={"row"}>
                                                                {
                                                                    filtereditems.map((item)=>(
                                                                        <>
                                                                            <div className={"col-md-6"}>
                                                                                <div className={"row px-3"} >
                                                                                    <div className="col-md-12 originals-list " style={{ borderTop:"0.5px solid rgba(235, 235, 235, 0.9)", paddingTop:"15px", marginTop:"15px"}}>
                                                                                        <Link onClick={()=>this.saveEvent(item.id)} to={`/templates/${item.id}`} style={{textDecoration:"none"}}>
                                                                                            <div className="card-body  p-0">
                                                                                                <div className={"row originals-list-view-items d-flex align-items-center"}>
                                                                                                    <div className={"col-md-3"}>
                                                                                                        <img src={item.image} style={{height:"105px", width:"105px", borderRadius:"5px"}}/>
                                                                                                    </div>
                                                                                                    <div className={"col-md-9"}>
                                                                                                        <h6 style={{color:"#070707", fontSize:"20px", textDecoration:"underline"}}>
                                                                                                            {item.title}
                                                                                                        </h6>
                                                                                                        <h6 style={{color:"#070707", fontSize:"12px", fontWeight:"400", lineHeight:"15px"}}>

                                                                                                        </h6>
                                                                                                        <h6 style={{color:"#00944D", fontSize:"10px", fontWeight:"400", lineHeight:"15px"}}>
                                                                                                            Read now
                                                                                                        </h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className={"col-md-4"}></div>
                                                                                    <div className={"col-md-2 d-flex align-items-center"}>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </>

                                                                    ))
                                                                }
                                                            </div>


                                                        </>

                                                        : <div className={"container d-flex justify-content-center"}>
                                                            <div className="search-message">
                                                                <img src={cards}/><br/>
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in the templates list, try our search
                                                                    engine instead</p>
                                                                <NotFound term={this.state.filter} />
                                                            </div>
                                                        </div>
                                                }

                                            </div>
                                    }

                                </div> :

                                //Grid view
                                <div>
                                    {
                                        this.state.loading ? <>
                                            <div className="row mt-5">

                                                {
                                                    [1, 2, 3, 4].map((n) => (
                                                        <div className="col-md-3">
                                                            <SkeletonImage key={n}/>
                                                        </div>
                                                    ) )
                                                }

                                            </div>

                                        </> :  <div className="row p-0 mt-5">
                                            {
                                                filtereditems.length > 0 ?
                                                    <>
                                                        {
                                                            filtereditems.map((item)=>(
                                                                <>

                                                                    <div className="col-md-4  mt-3">
                                                                        <Link onClick={()=>this.saveEvent(item.id)} onClick={()=>this.saveEvent()} to={`/templates/${item.id}`} style={{textDecoration:"none"}}>
                                                                            <div className="originals-card card-body h-100">
                                                                                <img src={item.image} style={{width:"100%"}}/>
                                                                                <div className="originals-list-title mt-3">
                                                                                    <h6>
                                                                                        {item.title}
                                                                                    </h6>
                                                                                    {/* <Link to={`/templates/${item.id}`} style={{textDecoration:"none"}}>
                                                                                    Open <MdTrendingFlat />
                                                                                </Link>*/}
                                                                                    <h6>

                                                                                    </h6>
                                                                                </div>

                                                                            </div>
                                                                        </Link>
                                                                    </div>

                                                                </>

                                                            ))
                                                        }

                                                    </>

                                                    : <div className={"container d-flex justify-content-center"}>
                                                        <div className="search-message">
                                                            <img src={cards}/><br/>
                                                            <h6>No results found for this keyword</h6>
                                                            <p>This word is not available in the templates list, try our search
                                                                engine instead</p>
                                                            <NotFound term={this.state.filter} />
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    }

                                </div>
                            }


                        </div>
                    </MediaQuery>
                </div>
                <Footer />
            </>
        );
    }
}
export default OriginalsList